import axios from "axios";
axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://pharmacy-backend.test';
axios.defaults.baseURL = 'http://137.184.89.55';        //DATA DEMO



    axios.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("token");
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.replace('login')
        } else {
            return Promise.reject(error.response);
        }
    }
);
